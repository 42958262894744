import React, { useState, useEffect, useLayoutEffect } from "react";
import { navigate } from "gatsby";
import TimePicker from "react-bootstrap-time-picker";
import Multiselect from "multiselect-react-dropdown";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Modal,
  Table,
} from "react-bootstrap";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { getToken } from "../utils/auth";
import { NotificationSuccess } from "../components/Helper";

export default function CreateCampaignPage() {
  return (
    <Layout>
      <CreateCampaign />
    </Layout>
  );
}

export function CreateCampaign({
  Values,
  Edit = false,
  ReloadCampaigns = false,
}) {
  const [Branches, setBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);

  useEffect(() => {
    GetLocationsList(setBranches);
  }, []);

  //Edit Campaigns
  useLayoutEffect(() => {
    if (!!Values) {
      switch (Values.TypeNumber) {
        case 1:
          document.getElementById("first2").click();
          break;
        case 2:
          document.getElementById("first3").click();
          break;
        default:
          document.getElementById("first1").click();
          break;
      }

      setSelectedBranches(
        Values?.Branches?.map((x) => {
          return x?.Branch;
        })
      );
      if (Values?.ExcludeWeekends) {
        if (Values?.TypeNumber === 0)
          document.getElementById("custom-switch").click();
        else document.getElementById("weekends1").click();
      }
      if (Values?.ExcludeHolidays)
        document.getElementById("Excludeholidays").click();

      if (Values?.state === 1) document.getElementById("state-switch").click();

      if (Values?.CustomizeTiming) {
        document.getElementById("timing").click();
        setTimeout(() => {
          (Values?.Timings ?? []).forEach((element) => {
            if (element?.DayName === "Monday") {
              settime(element?.OpenTime);
              settime01(element?.CloseTime);
            } else if (element?.DayName === "Tuesday") {
              settime02(element?.OpenTime);
              settime03(element?.CloseTime);
            } else if (element?.DayName === "Wednesday") {
              settime04(element?.OpenTime);
              settime05(element?.CloseTime);
            } else if (element?.DayName === "Thursday") {
              settime06(element?.OpenTime);
              settime07(element?.CloseTime);
            } else if (element?.DayName === "Friday") {
              settime08(element?.OpenTime);
              settime09(element?.CloseTime);
            } else if (element?.DayName === "Saturday") {
              settime10(element?.OpenTime);
              settime11(element?.CloseTime);
            } else if (element?.DayName === "Sunday") {
              settime12(element?.OpenTime);
              settime13(element?.CloseTime);
            }
          });
        }, 1000);
      }

      var buyitem1 = document.querySelector("#buyitem1");
      if (buyitem1) buyitem1.innerText = Values?.Type?.ToBuy;
      var buyitemy = document.querySelector("#buyitemy");
      if (buyitemy) buyitemy.innerText = Values?.Type?.ToBuy;

      var buyitem2 = document.querySelector("#buyitem2");
      if (buyitem2) buyitem2.innerText = Values?.Type?.ToBuy;
      var buyitemx = document.querySelector("#buyitemx");
      if (buyitemx) buyitemx.innerText = Values?.Type?.ToBuy;

      var itemestimated = document.querySelector("#itemestimated");
      if (itemestimated) itemestimated.innerText = Values?.Savings;

      var itemestimatedx = document.querySelector("#itemestimatedx");
      if (itemestimatedx) itemestimatedx.innerText = Values?.Savings;

      if (Values?.TypeNumber === 0) {
        if (isDate(Values, "Sunday")) document.getElementById("SunDay").click();
        if (isDate(Values, "Monday")) document.getElementById("MonDay").click();
        if (isDate(Values, "Tuesday")) document.getElementById("ThDay").click();
        if (isDate(Values, "Wednesday"))
          document.getElementById("WedDay").click();
        if (isDate(Values, "Thursday"))
          document.getElementById("ThuDay").click();
        if (isDate(Values, "Friday")) document.getElementById("FraDay").click();
        if (isDate(Values, "Saturday"))
          document.getElementById("SatDay").click();
      } else {
        if (isDate(Values, "Sunday"))
          document.getElementById("SunDay1").click();
        if (isDate(Values, "Monday"))
          document.getElementById("MonDay2").click();
        if (isDate(Values, "Tuesday"))
          document.getElementById("ThDay3").click();
        if (isDate(Values, "Wednesday"))
          document.getElementById("WedDay4").click();
        if (isDate(Values, "Thursday"))
          document.getElementById("ThuDay5").click();
        if (isDate(Values, "Friday"))
          document.getElementById("FraDay6").click();
        if (isDate(Values, "Saturday"))
          document.getElementById("SatDay7").click();
      }
    }
  }, [Values]);

  function isDate(item, name) {
    return !!item.Timings.find((x) => x?.DayName === name);
  }

  const [time, settime] = useState();
  const [time01, settime01] = useState();
  const [time02, settime02] = useState();
  const [time03, settime03] = useState();
  const [time04, settime04] = useState();
  const [time05, settime05] = useState();
  const [time06, settime06] = useState();
  const [time07, settime07] = useState();
  const [time08, settime08] = useState();
  const [time09, settime09] = useState();
  const [time10, settime10] = useState();
  const [time11, settime11] = useState();
  const [time12, settime12] = useState();
  const [time13, settime13] = useState();
  const [time14, settime14] = useState();
  const [time15, settime15] = useState();

  function myFunctionx() {
    var m = document.getElementById("buyx").value;
    document.getElementById("buyitemy").innerHTML = m;
  }
  function myFunctionxx() {
    var k = document.getElementById("buxy").value;
    document.getElementById("buyitemx").innerHTML = k;
  }
  function myFunction() {
    var x = document.getElementById("buyone").value;
    document.getElementById("buyitem1").innerHTML = x;
    document.getElementById("buyitem2").innerHTML = x;
  }
  function Money() {
    var p = document.getElementById("timeestimatedx").value;
    document.getElementById("itemestimatedx").innerHTML = p;
  }
  const email =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("email")
      : "";
  const code =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("code")
      : "";

  useEffect(() => {
    // Update the document title using the browser API

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      GuidCode: code,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Account/GuidLogin`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var json = JSON.parse(result);
        if (json.access_token) {
          localStorage.setItem("loginaccesstokec", json.access_token);
          localStorage.setItem("loginemail", json.userName);

          document.querySelector(".registerbutton").style.display = "none";
          document.querySelector(".loginbutton").style.display = "none";
          document.querySelector(".logoutbutton").style.display = "block";
          document.querySelector(".welocmetext").style.display = "block";
          document.getElementById("Name").innerHTML =
            localStorage.getItem("loginemail");
        }
      })
      .catch((error) => console.log("error", error));
  });

  function checkAllDay2() {
    var checkBox1 = document.getElementById("AllDay1");
    var itemout2 = document.getElementById("weekends1");
    var text1 = document.querySelector(".Sat");
    var text2 = document.querySelector(".Sun");
    var text3 = document.querySelector(".Mon");
    var text4 = document.querySelector(".Th");
    var text5 = document.querySelector(".Wed");
    var text6 = document.querySelector(".Thu");
    var text7 = document.querySelector(".Fra");
    if (checkBox1.checked == true) {
      document.getElementById("rowsunday").style.display = "flex";
      document.getElementById("rowsaturday").style.display = "flex";
      document.getElementById("rowfriday").style.display = "flex";
      document.getElementById("rowthursday").style.display = "flex";
      document.getElementById("rowwednesday").style.display = "flex";
      document.getElementById("rowtuesday").style.display = "flex";
      document.getElementById("rowmonday").style.display = "flex";

      if (itemout2.checked == true) {
        text1.style.color = "#292b2c";
        text2.style.color = "#292b2c";
        text7.style.color = "#292b2c";
        document.getElementById("SatDay7").checked = false;
        document.getElementById("SatDay7").disabled = true;

        /* document.getElementById("FraDay6").checked = false;
        document.getElementById("FraDay6").disabled = true; */

        document.getElementById("SunDay1").checked = false;
        document.getElementById("SunDay1").disabled = true;
      } else {
        text1.style.color = "#0275d8";
        text2.style.color = "#0275d8";
        text7.style.color = "#0275d8";

        document.getElementById("SatDay7").checked = true;
        document.getElementById("SatDay7").disabled = false;

        /*  document.getElementById("FraDay6").checked = true;
        document.getElementById("FraDay6").disabled = false; */

        document.getElementById("SunDay1").checked = true;
        document.getElementById("SunDay1").disabled = false;
      }

      document.getElementById("MonDay2").checked = true;
      text3.style.color = "#0275d8";
      document.getElementById("ThDay3").checked = true;
      text4.style.color = "#0275d8";
      document.getElementById("WedDay4").checked = true;
      text5.style.color = "#0275d8";
      document.getElementById("ThuDay5").checked = true;
      text6.style.color = "#0275d8";
      document.getElementById("FraDay6").checked = true;
      text7.style.color = "#0275d8";
    } else {
      document.getElementById("rowsunday").style.display = "none";
      document.getElementById("rowsaturday").style.display = "none";
      document.getElementById("rowfriday").style.display = "none";
      document.getElementById("rowthursday").style.display = "none";
      document.getElementById("rowwednesday").style.display = "none";
      document.getElementById("rowtuesday").style.display = "none";
      document.getElementById("rowmonday").style.display = "none";

      text1.style.color = "#292b2c";
      document.getElementById("SatDay7").checked = false;
      text2.style.color = "#292b2c";
      document.getElementById("MonDay2").checked = false;
      text3.style.color = "#292b2c";
      document.getElementById("ThDay3").checked = false;
      text4.style.color = "#292b2c";
      document.getElementById("WedDay4").checked = false;
      text5.style.color = "#292b2c";
      document.getElementById("ThuDay5").checked = false;
      text6.style.color = "#292b2c";
      document.getElementById("FraDay6").checked = false;
      text7.style.color = "#292b2c";
      document.getElementById("SunDay1").checked = false;
    }
    CheckInputDays1();
  }

  function checkAllDay() {
    var checkBox = document.getElementById("AllDay");
    var itemout = document.getElementById("custom-switch");

    var text1 = document.getElementById("Sat");
    var text2 = document.getElementById("Sun");
    var text3 = document.getElementById("Mon");
    var text4 = document.getElementById("Th");
    var text5 = document.getElementById("Wed");
    var text6 = document.getElementById("Thu");
    var text7 = document.getElementById("Fra");
    if (checkBox.checked == true) {
      document.getElementById("rowsunday").style.display = "flex";
      document.getElementById("rowsaturday").style.display = "flex";
      document.getElementById("rowfriday").style.display = "flex";
      document.getElementById("rowthursday").style.display = "flex";
      document.getElementById("rowwednesday").style.display = "flex";
      document.getElementById("rowtuesday").style.display = "flex";
      document.getElementById("rowmonday").style.display = "flex";
      text1.style.color = "#0275d8";

      if (itemout.checked == true) {
        text1.style.color = "#292b2c";
        text2.style.color = "#292b2c";
        document.getElementById("SatDay").checked = false;
        document.getElementById("SatDay").disabled = true;
        /* document.getElementById("FraDay").checked = false;
        document.getElementById("FraDay").disabled = true; */
        document.getElementById("SunDay").checked = false;
        document.getElementById("SunDay").disabled = true;
      } else {
        text1.style.color = "#0275d8";
        text2.style.color = "#0275d8";
        document.getElementById("SatDay").checked = true;
        document.getElementById("SatDay").disabled = false;
        /* document.getElementById("FraDay").checked = true;
        document.getElementById("FraDay").disabled = false; */
        document.getElementById("SunDay").checked = true;
        document.getElementById("SunDay").disabled = false;
      }

      //document.getElementById("SunDay").checked = true;
      //text2.style.color = "#0275d8";
      document.getElementById("MonDay").checked = true;
      text3.style.color = "#0275d8";
      document.getElementById("FraDay").checked = true;
      text7.style.color = "#0275d8";
      document.getElementById("ThDay").checked = true;
      text4.style.color = "#0275d8";
      document.getElementById("WedDay").checked = true;
      text5.style.color = "#0275d8";
      document.getElementById("ThuDay").checked = true;
      text6.style.color = "#0275d8";
    } else {
      document.getElementById("rowsunday").style.display = "none";
      document.getElementById("rowsaturday").style.display = "none";
      document.getElementById("rowfriday").style.display = "none";
      document.getElementById("rowthursday").style.display = "none";
      document.getElementById("rowwednesday").style.display = "none";
      document.getElementById("rowtuesday").style.display = "none";
      document.getElementById("rowmonday").style.display = "none";
      text1.style.color = "#292b2c";
      document.getElementById("SatDay").checked = false;
      text2.style.color = "#292b2c";
      document.getElementById("MonDay").checked = false;
      text3.style.color = "#292b2c";
      document.getElementById("ThDay").checked = false;
      text4.style.color = "#292b2c";
      document.getElementById("WedDay").checked = false;
      text5.style.color = "#292b2c";
      document.getElementById("ThuDay").checked = false;
      text6.style.color = "#292b2c";
      document.getElementById("FraDay").checked = false;
      text7.style.color = "#292b2c";
      document.getElementById("SunDay").checked = false;
    }

    CheckInputDays();
  }

  function weekends() {
    var checkBox = document.getElementById("custom-switch");
    var checkBox1 = document.getElementById("SatDay");
    var checkBox3 = document.getElementById("SunDay");
    var text1 = document.getElementById("Sat");
    var text3 = document.getElementById("Sun");

    if (checkBox.checked == true) {
      text1.style.color = "#292b2c";
      text3.style.color = "#292b2c";
      checkBox1.checked = false;
      checkBox3.checked = false;
      checkBox1.disabled = true;
      checkBox3.disabled = true;
    } else {
      text1.style.color = "#0275d8";
      text3.style.color = "#0275d8";
      checkBox1.disabled = false;
      checkBox3.disabled = false;

      var all = document.getElementById("AllDay");
      if (all.checked === true) {
        checkBox1.checked = true;
        checkBox3.checked = true;
      } else {
        checkBox1.checked = false;
        checkBox3.checked = false;
      }
    }
    CheckInputDays();
  }
  function check() {
    var checkBox = document.getElementById("SatDay");
    var text = document.getElementById("Sat");
    var SatDaycheck = document.getElementById("SatDay");
    var SatDaycheck01 = document.getElementById("SatDay7");
    if (SatDaycheck.checked == true || SatDaycheck01.checked == true) {
      document.getElementById("rowsaturday").style.display = "flex";
    } else {
      document.getElementById("rowsaturday").style.display = "none";
    }
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }
  function check7() {
    var checkBox = document.getElementById("SatDay7");
    var text = document.querySelector(".Sat");
    var SatDaycheck = document.getElementById("SatDay");
    var SatDaycheck01 = document.getElementById("SatDay7");
    if (SatDaycheck.checked == true || SatDaycheck01.checked == true) {
      document.getElementById("rowsaturday").style.display = "flex";
    } else {
      document.getElementById("rowsaturday").style.display = "none";
    }
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }

  function checkFra6() {
    var checkBox = document.getElementById("FraDay6");
    var text = document.querySelector(".Fra");
    var FraDaycheck = document.getElementById("FraDay");
    var FraDaycheck01 = document.getElementById("FraDay6");
    if (FraDaycheck.checked == true || FraDaycheck01.checked == true) {
      document.getElementById("rowfriday").style.display = "flex";
    } else {
      document.getElementById("rowfriday").style.display = "none";
    }

    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }

  function checkThu5() {
    var checkBox = document.getElementById("ThuDay5");
    var text = document.querySelector(".Thu");
    var ThuDaycheck = document.getElementById("ThuDay5");
    var ThuDaycheck01 = document.getElementById("ThuDay");
    if (ThuDaycheck.checked == true || ThuDaycheck01.checked == true) {
      document.getElementById("rowthursday").style.display = "flex";
    } else {
      document.getElementById("rowthursday").style.display = "none";
    }
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }

  function checkWed4() {
    var checkBox = document.getElementById("WedDay4");
    var text = document.querySelector(".Wed");
    var WedDaycheck = document.getElementById("WedDay");
    var WedDaycheck01 = document.getElementById("WedDay4");
    if (WedDaycheck.checked == true || WedDaycheck01.checked == true) {
      document.getElementById("rowwednesday").style.display = "flex";
    } else {
      document.getElementById("rowwednesday").style.display = "none";
    }
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }
  function checkTh3() {
    var checkBox = document.getElementById("ThDay3");
    var text = document.querySelector(".Th");
    var Tursdaycheck = document.getElementById("ThDay");
    var Tursdaycheck01 = document.getElementById("ThDay3");
    if (Tursdaycheck.checked == true || Tursdaycheck01.checked == true) {
      document.getElementById("rowtuesday").style.display = "flex";
    } else {
      document.getElementById("rowtuesday").style.display = "none";
    }
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }
  function checkMon2() {
    var checkBox = document.getElementById("MonDay2");
    var text = document.querySelector(".Mon");
    var Mondaycheck = document.getElementById("MonDay");
    var Mondaycheck01 = document.getElementById("MonDay2");
    if (Mondaycheck.checked == true || Mondaycheck01.checked == true) {
      document.getElementById("rowmonday").style.display = "flex";
    } else {
      document.getElementById("rowmonday").style.display = "none";
    }
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }
  function checkSun1() {
    var checkBox = document.getElementById("SunDay1");
    var text = document.querySelector(".Sun");
    var SunDaycheck = document.getElementById("SunDay");
    var SunDaycheck01 = document.getElementById("SunDay1");
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }

    if (SunDaycheck.checked == true || SunDaycheck01.checked == true) {
      document.getElementById("rowsunday").style.display = "flex";
    } else {
      document.getElementById("rowsunday").style.display = "none";
    }
  }
  function checkSun() {
    var checkBox = document.getElementById("SunDay");
    var text = document.getElementById("Sun");
    var SunDaycheck = document.getElementById("SunDay");
    var SunDaycheck01 = document.getElementById("SunDay1");
    if (SunDaycheck.checked == true || SunDaycheck01.checked == true) {
      document.getElementById("rowsunday").style.display = "flex";
    } else {
      document.getElementById("rowsunday").style.display = "none";
    }
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }
  function checkMon() {
    var Mondaycheck = document.getElementById("MonDay");
    var Mondaycheck01 = document.getElementById("MonDay2");
    if (Mondaycheck.checked == true || Mondaycheck01.checked == true) {
      document.getElementById("rowmonday").style.display = "flex";
    } else {
      document.getElementById("rowmonday").style.display = "none";
    }
    var checkBox = document.getElementById("MonDay");
    var text = document.getElementById("Mon");
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }
  function checkTh() {
    var checkBox = document.getElementById("ThDay");
    var text = document.getElementById("Th");
    var Tursdaycheck = document.getElementById("ThDay");
    var Tursdaycheck01 = document.getElementById("ThDay3");
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
    if (Tursdaycheck.checked == true || Tursdaycheck01.checked == true) {
      document.getElementById("rowtuesday").style.display = "flex";
    } else {
      document.getElementById("rowtuesday").style.display = "none";
    }
  }
  function checkWed() {
    var checkBox = document.getElementById("WedDay");
    var text = document.getElementById("Wed");
    var WedDaycheck = document.getElementById("WedDay");
    var WedDaycheck01 = document.getElementById("WedDay4");
    if (WedDaycheck.checked == true || WedDaycheck01.checked == true) {
      document.getElementById("rowwednesday").style.display = "flex";
    } else {
      document.getElementById("rowwednesday").style.display = "none";
    }
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }

  function checkThu() {
    var checkBox = document.getElementById("ThuDay");
    var text = document.getElementById("Thu");
    var ThuDaycheck = document.getElementById("ThuDay5");
    var ThuDaycheck01 = document.getElementById("ThuDay");
    if (ThuDaycheck.checked == true || ThuDaycheck01.checked == true) {
      document.getElementById("rowthursday").style.display = "flex";
    } else {
      document.getElementById("rowthursday").style.display = "none";
    }
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }
  function checkFra() {
    var checkBox = document.getElementById("FraDay");
    var text = document.getElementById("Fra");
    var FraDaycheck = document.getElementById("FraDay");
    var FraDaycheck01 = document.getElementById("FraDay6");
    if (FraDaycheck.checked == true || FraDaycheck01.checked == true) {
      document.getElementById("rowfriday").style.display = "flex";
    } else {
      document.getElementById("rowfriday").style.display = "none";
    }
    if (checkBox.checked == true) {
      text.style.color = "#0275d8";
    } else {
      text.style.color = "#292b2c";
    }
  }

  function Timing() {
    var checkBox = document.getElementById("timing");
    var text = document.getElementById("days");
    if (checkBox.checked == true) {
      text.style.display = "block";
    } else {
      text.style.display = "none";
    }
  }
  function buyxgety() {
    document.querySelector("#buyonegetone").style.display = "none";
    document.querySelector("#buyxgety").style.display = "block";
    document.querySelector("#buyxesection").style.display = "block";
    document.querySelector("#bueyonesection").style.display = "none";
    document.querySelector("#getonlyy").style.display = "block";
    document.querySelector("#getonlyone").style.display = "none";
    document.querySelector("#exclude").style.display = "block";
    document.querySelector("#excludeone").style.display = "none";
  }
  function weekends1() {
    var checkBox = document.getElementById("weekends1");
    var checkBox1 = document.getElementById("SatDay7");
    var checkBox3 = document.getElementById("SunDay1");
    var text1 = document.querySelector(".Sat");
    var text3 = document.querySelector(".Sun");

    if (checkBox.checked == true) {
      text1.style.color = "#292b2c";
      text3.style.color = "#292b2c";
      checkBox1.checked = false;
      checkBox3.checked = false;
      checkBox1.disabled = true;
      checkBox3.disabled = true;
    } else {
      text1.style.color = "#0275d8";
      text3.style.color = "#0275d8";
      checkBox1.disabled = false;
      checkBox3.disabled = false;
      checkBox1.checked = false;
      checkBox3.checked = false;
    }
    CheckInputDays1();
  }

  function CheckInputDays() {
    var check01 = document.getElementById("SatDay")?.checked;
    var check02 = document.getElementById("FraDay")?.checked;
    var check03 = document.getElementById("ThuDay")?.checked;
    var check04 = document.getElementById("WedDay")?.checked;
    var check05 = document.getElementById("ThDay")?.checked;
    var check06 = document.getElementById("MonDay")?.checked;
    var check07 = document.getElementById("SunDay")?.checked;

    document.getElementById("rowsaturday").style.display = check01
      ? "flex"
      : "none";
    document.getElementById("rowfriday").style.display = check02
      ? "flex"
      : "none";
    document.getElementById("rowthursday").style.display = check03
      ? "flex"
      : "none";
    document.getElementById("rowwednesday").style.display = check04
      ? "flex"
      : "none";
    document.getElementById("rowtuesday").style.display = check05
      ? "flex"
      : "none";
    document.getElementById("rowmonday").style.display = check06
      ? "flex"
      : "none";
    document.getElementById("rowsunday").style.display = check07
      ? "flex"
      : "none";
  }

  function CheckInputDays1() {
    var check01 = document.getElementById("SatDay7")?.checked;
    var check02 = document.getElementById("FraDay6")?.checked;
    var check03 = document.getElementById("ThuDay5")?.checked;
    var check04 = document.getElementById("WedDay4")?.checked;
    var check05 = document.getElementById("ThDay3")?.checked;
    var check06 = document.getElementById("MonDay2")?.checked;
    var check07 = document.getElementById("SunDay1")?.checked;

    document.getElementById("rowsaturday").style.display = check01
      ? "flex"
      : "none";
    document.getElementById("rowfriday").style.display = check02
      ? "flex"
      : "none";
    document.getElementById("rowthursday").style.display = check03
      ? "flex"
      : "none";
    document.getElementById("rowwednesday").style.display = check04
      ? "flex"
      : "none";
    document.getElementById("rowtuesday").style.display = check05
      ? "flex"
      : "none";
    document.getElementById("rowmonday").style.display = check06
      ? "flex"
      : "none";
    document.getElementById("rowsunday").style.display = check07
      ? "flex"
      : "none";
  }

  function buyonegetone() {
    document.querySelector("#buyonegetone").style.display = "block";
    document.querySelector("#buyxgety").style.display = "none";
    document.querySelector("#buyxesection").style.display = "none";
    document.querySelector("#getonlyy").style.display = "none";
    document.querySelector("#bueyonesection").style.display = "block";
    document.querySelector("#getonlyone").style.display = "block";
    document.querySelector("#exclude").style.display = "none";
    document.querySelector("#excludeone").style.display = "block";
  }
  function bundle() {
    document.querySelector("#buyonegetone").style.display = "none";
    document.querySelector("#buyxgety").style.display = "block";
    document.querySelector("#buyxesection").style.display = "block";
  }

  function mySecondFunction() {
    var y = document.getElementById("timeestimated").value;
    document.getElementById("itemestimated").innerHTML = y;
  }
  function handleTimeChange(time) {
    settime(time);
  }
  function handleTimeChange01(time01) {
    settime01(time01);
  }
  function handleTimeChange02(time02) {
    settime02(time02);
  }
  function handleTimeChange03(time03) {
    settime03(time03);
  }
  function handleTimeChange04(time04) {
    settime04(time04);
  }
  function handleTimeChange05(time05) {
    settime05(time05);
  }
  function handleTimeChange06(time06) {
    settime06(time06);
  }
  function handleTimeChange07(time07) {
    settime07(time07);
  }
  function handleTimeChange08(time08) {
    settime08(time08);
  }
  function handleTimeChange09(time09) {
    settime09(time09);
  }
  function handleTimeChange10(time10) {
    settime10(time10);
  }
  function handleTimeChange11(time11) {
    settime11(time11);
  }
  function handleTimeChange12(time12) {
    settime12(time12);
  }
  function handleTimeChange13(time13) {
    settime13(time13);
  }
  function handleClickEvent() {
    var day1 = document?.getElementById("TimeOpenMonday")?.value;
    var day1result = new Date(day1 * 1000).toISOString().substr(11, 5);

    var day2 = document?.getElementById("TimeCloseMonday")?.value;
    var day2result = new Date(day2 * 1000).toISOString().substr(11, 5);

    var day3 = document?.getElementById("TimeOpenTuesday")?.value;
    var day3result = new Date(day3 * 1000).toISOString().substr(11, 5);

    var day4 = document?.getElementById("TimeCloseTuesday")?.value;
    var day4result = new Date(day4 * 1000).toISOString().substr(11, 5);

    var day5 = document?.getElementById("TimeOpenWednesday")?.value;
    var day5result = new Date(day5 * 1000).toISOString().substr(11, 5);

    var day05 = document?.getElementById("TimeCloseWednesday")?.value;
    var day05result = new Date(day05 * 1000).toISOString().substr(11, 5);

    var day6 = document?.getElementById("TimeOpenThursday")?.value;
    var day6result = new Date(day6 * 1000).toISOString().substr(11, 5);

    var day7 = document?.getElementById("TimeCloseThursday")?.value;
    var day7result = new Date(day7 * 1000).toISOString().substr(11, 5);

    var day8 = document?.getElementById("TimeOpenFriday")?.value;
    var day8result = new Date(day8 * 1000).toISOString().substr(11, 5);

    var day9 = document?.getElementById("TimeCloseFriday")?.value;
    var day9result = new Date(day9 * 1000).toISOString().substr(11, 5);

    var day10 = document?.getElementById("TimeOpenSaturday")?.value;
    var day10result = new Date(day10 * 1000).toISOString().substr(11, 5);

    var day11 = document?.getElementById("TimeCloseSaturday")?.value;
    var day11result = new Date(day11 * 1000).toISOString().substr(11, 5);

    var day12 = document?.getElementById("TimeOpenSunday")?.value;
    var day12result = new Date(day12 * 1000).toISOString().substr(11, 5);

    var day13 = document?.getElementById("TimeCloseSunday")?.value;
    var day13result = new Date(day13 * 1000).toISOString().substr(11, 5);

    let Type = document.querySelector(".campaignTab.active")?.innerText;
    if (Type === "Buy One Get One") Type = 0;
    else if (Type === "Buy X Get Y") Type = 1;
    else if (Type === "Buy Bundle Get Unit") Type = 2;

    var viewModel = {
      state: document.getElementById("state-switch")?.checked ? 1 : 0,
      Branchs: selectedBranches,
      typeoffer: document.querySelector(".campaignTab.active")?.innerText,
      Description: document?.querySelector("textarea#formFinePrint")?.value,
      Excludeholidays: document.getElementById("Excludeholidays")?.checked,
      excludewekkends:
        document.getElementById("weekends1")?.checked +
        document.getElementById("custom-switch")?.checked,
      buy:
        Type === 0
          ? document.getElementById("buyitem1")?.innerText
          : document.getElementById("buyitemy")?.innerText,
      get:
        Type === 0
          ? document.getElementById("buyitem2")?.innerText
          : document.getElementById("buyitemx")?.innerText,
      money:
        Type === 0
          ? document.getElementById("timeestimated")?.value
          : document.getElementById("timeestimatedx")?.value,

      valuecheck: document.getElementById("SatDay7")?.checked,
      valuecheck2: document.getElementById("FraDay6")?.checked,
      valuecheck3: document.getElementById("ThuDay5")?.checked,
      valuecheck4: document.getElementById("WedDay4")?.checked,
      valuecheck5: document.getElementById("ThDay3")?.checked,
      valuecheck6: document.getElementById("MonDay2")?.checked,
      valuecheck7: document.getElementById("SunDay1")?.checked,
      valuecheck01: document.getElementById("SatDay")?.checked,
      valuecheck02: document.getElementById("FraDay")?.checked,
      valuecheck03: document.getElementById("ThuDay")?.checked,
      valuecheck04: document.getElementById("WedDay")?.checked,
      valuecheck05: document.getElementById("ThDay")?.checked,
      valuecheck06: document.getElementById("MonDay")?.checked,
      valuecheck07: document.getElementById("SunDay")?.checked,

      timeOpenMonday: day1result,
      timeCloseMonday: day2result,

      timeOpenTuesday1: day3result,
      timeCloseTuesday2: day4result,

      timeOpenWednesday3: day5result,
      timeCloseWednesday4: day05result,

      timeOpenThursday5: day6result,
      timeCloseThursday6: day7result,

      timeOpenFriday7: day8result,
      timeCloseFriday8: day9result,

      TimeOpenSaturday: day10result,
      TimeCloseSaturday: day11result,

      TimeOpenSunday: day12result,
      TimeCloseSunday: day13result,

      CustomizeTiming: document?.getElementById("timing")?.checked,
    };

    if (Edit) UpdateCampaign(viewModel, Values?.Id, ReloadCampaigns);
    else
      navigate("/review", {
        state: viewModel,
      });
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showHolidays, setshowHolidays] = useState(false);

  const handleCloseHolidays = () => setshowHolidays(false);
  const handleShowHolidays = () => setshowHolidays(true);

  return (
    <main>
      <Container>
        {!Edit && (
          <Row>
            <div className="col-xl-3 col-lg-3 col-md-3 align-self-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-5">
                  <li class="breadcrumb-item primary-color">
                    <Link
                      to="/app/dashboard"
                      className="primary-color text-decoration-none"
                    >
                      Campaigns
                    </Link>
                  </li>
                  <li class="breadcrumb-item primary-color" aria-current="page">
                    Create New
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <h1 class="fs-48 fw-800 mb-5">Create new campaign</h1>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 text-end pt-3">
              <Link
                to=""
                className="text-dark fw-airlight text-decoration-none"
              >
                <span className="mb-4 align-text-bottom">
                  {" "}
                  <img src="../img/Tuto.svg" alt="btn tuto" className="me-2" />
                </span>
                Watch tutorial
              </Link>
            </div>
          </Row>
        )}
        <secion className="Card-Ticket" id="buyonegetone">
          <div
            className={`col-xl-3 col-lg-3 col-md-6 mx-auto ticket ${
              Edit && "col-xl-6"
            }`}
          >
            <Card className="">
              <Card.Body>
                <Row>
                  <Col xl="8" lg="8" md="6" xs="6">
                    <Row>
                      <Col xl="4" lg="4" md="5" xs="6">
                        <img
                          src="../img/LogoCreate.svg"
                          alt="logo"
                          className="me-2"
                        />
                      </Col>
                      <Col xl="8" lg="8" md="7" xs="6">
                        <p className="text-muted fs-8 mb-0"> BUY</p>
                        <p className="fs-10 mb-1" id="buyitem1"></p>
                        <p className="text-muted fs-8 mb-0"> GET</p>
                        <p className="fs-10 mb-0" id="buyitem2"></p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="4" lg="4" md="6" xs="6">
                    <p className="text-muted fs-8 mb-0">VALIDITY</p>
                    <span className="d-flex">
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Sun">
                        S
                      </span>
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Mon">
                        {" "}
                        M
                      </span>
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Th">
                        {" "}
                        T
                      </span>
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Wed">
                        {" "}
                        W{" "}
                      </span>
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Thu">
                        {" "}
                        T
                      </span>
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Fra">
                        {" "}
                        F{" "}
                      </span>
                      <span className=" pt-0 ps-0 pe-1 fs-8 mb-0" id="Sat">
                        {" "}
                        S
                      </span>
                    </span>
                    <p className="text-muted fs-8 mb-0"> EST. SAVINGS</p>
                    <p className="primary-color fs-10 mb-0">
                      AED <span id="itemestimated"></span>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="bg-white">
                <button className="btn btn-primary text-white text-center w-100">
                  Voucher Details
                </button>
              </Card.Footer>
            </Card>
          </div>
        </secion>

        <secion
          className="Card-Ticket"
          id="buyxgety"
          style={{ display: "none" }}
        >
          <div
            className={`col-xl-3 col-lg-3 col-md-6 mx-auto ticket ${
              Edit && "col-xl-6"
            }`}
          >
            <Card className="">
              <Card.Body>
                <Row>
                  <Col xl="8" lg="8" md="6" xs="6">
                    <Row>
                      <Col xl="4" lg="4" md="5" xs="6">
                        <img
                          src="../img/bxgy.png"
                          alt="logo"
                          className="me-2"
                          id="switchimg"
                        />
                      </Col>
                      <Col xl="8" lg="8" md="7" xs="6">
                        <p className="text-muted fs-8 mb-0"> BUY</p>
                        <p className="fs-10 mb-1" id="buyitemy"></p>
                        <p className="text-muted fs-8 mb-0"> GET</p>
                        <p className="fs-10 mb-0" id="buyitemx"></p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="4" lg="4" md="6" xs="6">
                    <p className="text-muted fs-8 mb-0">VALIDITY</p>
                    <span className="d-flex">
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0 Sun">S</span>
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0 Mon"> M</span>
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0 Th"> T</span>
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0 Wed">
                        {" "}
                        W{" "}
                      </span>
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0 Thu"> T</span>
                      <span className=" pt-0 ps-0 pe-1  fs-8 mb-0 Fra">
                        {" "}
                        F{" "}
                      </span>
                      <span className=" pt-0 ps-0 pe-1 fs-8 mb-0 Sat"> S</span>
                    </span>
                    <p className="text-muted fs-8 mb-0"> EST. SAVINGS</p>
                    <p className="primary-color fs-10 mb-0">
                      AED <span id="itemestimatedx"></span>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="bg-white">
                <button className="btn btn-primary text-white text-center w-100">
                  Voucher Details
                </button>
              </Card.Footer>
            </Card>
          </div>
        </secion>
        <section className="--Title">
          <div
            className={`col-xl-3 col-lg-3 col-md-12 mx-auto mt-4 mb-5 text-center ${
              Edit && "col-xl-12"
            }`}
          >
            <p className="text-muted fs-13">Your voucher will look like this</p>
          </div>
          <div className={`col-xl-3 col-lg-3 col-md-12 mx-auto mb-4`}>
            <p className="text-muted fs-13">Choose offer type</p>
          </div>
        </section>
        <section className="offer-type">
          <div
            className={`col-xl-3 col-lg-3 col-md-12 mx-auto ${
              Edit && "col-xl-6"
            }`}
          >
            <Row>
              <div
                className="col-xl-4 col-lg-4 col-md-4 col-4"
                onClick={() => {
                  buyonegetone();

                  var element = document.getElementById("first1");
                  element.classList.add("active");

                  var element1 = document.getElementById("first2");
                  element1.classList.remove("active");

                  var element2 = document.getElementById("first3");
                  element2.classList.remove("active");
                }}
              >
                <div className="card campaignTab" id="first1" state="0">
                  <div className="text-center card-body">
                    <p className="text-muted fs-14 mb-0 buyonegetone">
                      Buy One Get One{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-4 col-4"
                onClick={() => {
                  buyxgety();
                  document.getElementById("switchimg").src = "../img/bxgy.png";

                  var element = document.getElementById("first1");
                  element.classList.remove("active");

                  var element1 = document.getElementById("first2");
                  element1.classList.add("active");

                  var element2 = document.getElementById("first3");
                  element2.classList.remove("active");
                }}
              >
                <div className="card campaignTab" id="first2" state="1">
                  <div className="text-center card-body">
                    <p className="text-muted fs-14 mb-0 buyxgety">
                      Buy X Get Y
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-4 col-4"
                onClick={() => {
                  document.getElementById("switchimg").src = "../img/bbgu.png";

                  var element = document.getElementById("first1");
                  element.classList.remove("active");

                  var element1 = document.getElementById("first2");
                  element1.classList.remove("active");

                  var element2 = document.getElementById("first3");
                  element2.classList.add("active");

                  buyxgety();
                }}
              >
                <div className="card campaignTab" id="first3" state="2">
                  <div className="text-center card-body">
                    <p className="text-muted fs-14 mb-0 bundleitem">
                      Buy Bundle Get Unit
                    </p>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </section>
        <section className="--Title">
          <div className="col-xl-3 col-lg-3 col-md-12 mx-auto mt-4 mb-3 text-center">
            <a
              role={"button"}
              className="primary-color fw-500 fs-14 opacity-8 text-decoration-none"
              onClick={handleShow}
            >
              Compare offer types
            </a>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Offer Types</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <React.Fragment>
                <h5>Buy 1 Get 1</h5>
                <img src="../img/LogoCreate.svg" alt="logo" className="me-2" />
                <p className="text-muted fw-500 fw-airlight">
                  Buy 1 Get 1 deals are best suited to couples, groups of
                  friends of co-workers
                </p>
              </React.Fragment>
              <React.Fragment>
                <h5>Buy X Get Y</h5>
                <img src="../img/bxgy.png" alt="logo" className="me-2" />
                <p className="text-muted fw-500 fw-airlight">
                  Buy X Get Y deals are best suited for solo customers
                </p>
              </React.Fragment>
              <React.Fragment>
                <h5> Buy Bundle Get Unit</h5>
                <img src="../img/bbgu.png" alt="logo" className="me-2" />
                <p className="text-muted fw-500 fw-airlight">
                  Buy Bundle Get Unit deals are best suited for large volume
                  buyers, like families or big groups.
                </p>
              </React.Fragment>
            </Modal.Body>
          </Modal>
        </section>
        <section className="form-offer">
          <div
            className={`col-xl-3 col-lg-3 col-md-12 mx-auto ${
              Edit && "col-xl-6"
            }`}
          >
            <div>
              <div id="bueyonesection">
                <Form.Group className="mb-3" controlId="formoffered">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                    What’s being offered?
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="example: Sushi Platter"
                    id="buyone"
                    defaultValue={
                      Values?.TypeNumber === 0 ? Values?.Type?.ToBuy : ""
                    }
                    onChange={(e) => {
                      myFunction();
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                    Estimated savings
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="AED"
                    id="timeestimated"
                    defaultValue={
                      Values?.TypeNumber === 0 ? Values?.Savings : ""
                    }
                    onChange={(e) => {
                      mySecondFunction();
                    }}
                  />
                </Form.Group>
              </div>

              <div id="buyxesection" style={{ display: "none" }}>
                <Form.Group className="mb-3" controlId="formoffered">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                    What should they buy?
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="example: Sushi Platter"
                    id="buyx"
                    defaultValue={
                      Values?.TypeNumber !== 0 ? Values?.Type?.ToBuy : ""
                    }
                    onChange={(e) => {
                      myFunctionx();
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formoffered">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                    What should they get?
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="example: Sushi Platter"
                    id="buxy"
                    defaultValue={
                      Values?.TypeNumber !== 0 ? Values?.Type?.Bonus : ""
                    }
                    onChange={(e) => {
                      myFunctionxx();
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                    Estimated savings
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="AED"
                    id="timeestimatedx"
                    defaultValue={
                      Values?.TypeNumber !== 0 ? Values?.Savings : ""
                    }
                    onChange={(e) => {
                      Money();
                    }}
                  />
                </Form.Group>
              </div>
              <Form.Group className="mb-3 text-start Branch">
                <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                  Select Branch
                </Form.Label>
                <Multiselect
                  selectionLimit={5}
                  isObject={false}
                  className="px-0 py-2"
                  showArrow={true}
                  selectedValues={selectedBranches}
                  onSelect={(e) => {
                    setSelectedBranches(e);
                  }}
                  onRemove={(e) => {
                    setSelectedBranches(e);
                  }}
                  options={Branches?.map((e) => {
                    return e?.Branch;
                  })}
                />
              </Form.Group>
              <div id="getonlyone">
                <Form.Group className="mb-3 custom-checkbox">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                    Day of the week
                  </Form.Label>
                  <div className="d-flex">
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">All</p>
                      <Form.Check
                        type="checkbox"
                        id="AllDay"
                        onChange={(e) => {
                          checkAllDay();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Sun</p>
                      <Form.Check
                        type="checkbox"
                        id="SunDay"
                        onChange={(e) => {
                          checkSun();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Mon</p>
                      <Form.Check
                        type="checkbox"
                        id="MonDay"
                        onChange={(e) => {
                          checkMon();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Tue</p>
                      <Form.Check
                        type="checkbox"
                        id="ThDay"
                        onChange={(e) => {
                          checkTh();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Wed</p>
                      <Form.Check
                        type="checkbox"
                        id="WedDay"
                        onChange={(e) => {
                          checkWed();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Thu</p>
                      <Form.Check
                        type="checkbox"
                        id="ThuDay"
                        onChange={(e) => {
                          checkThu();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Fri</p>
                      <Form.Check
                        type="checkbox"
                        id="FraDay"
                        onChange={(e) => {
                          checkFra();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Sat</p>
                      <Form.Check
                        type="checkbox"
                        id="SatDay"
                        onChange={(e) => {
                          check();
                        }}
                      />
                    </Link>
                  </div>
                </Form.Group>
              </div>

              <div id="getonlyy" style={{ display: "none" }}>
                <Form.Group className="mb-3 custom-checkbox">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                    Day of the week
                  </Form.Label>
                  <div className="d-flex">
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">All</p>
                      <Form.Check
                        type="checkbox"
                        id="AllDay1"
                        onChange={(e) => {
                          checkAllDay2();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Sun</p>
                      <Form.Check
                        type="checkbox"
                        id="SunDay1"
                        onChange={(e) => {
                          checkSun1();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Mon</p>
                      <Form.Check
                        type="checkbox"
                        id="MonDay2"
                        onChange={(e) => {
                          checkMon2();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Tue</p>
                      <Form.Check
                        type="checkbox"
                        id="ThDay3"
                        onChange={(e) => {
                          checkTh3();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Wed</p>
                      <Form.Check
                        type="checkbox"
                        id="WedDay4"
                        onChange={(e) => {
                          checkWed4();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Thu</p>
                      <Form.Check
                        type="checkbox"
                        id="ThuDay5"
                        onChange={(e) => {
                          checkThu5();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Fri</p>
                      <Form.Check
                        type="checkbox"
                        id="FraDay6"
                        onChange={(e) => {
                          checkFra6();
                        }}
                      />
                    </Link>
                    <Link className="nav-link ps-0 pe-2">
                      <p className="text-muted mb-1">Sat</p>
                      <Form.Check
                        type="checkbox"
                        id="SatDay7"
                        onChange={(e) => {
                          check7();
                        }}
                      />
                    </Link>
                  </div>
                </Form.Group>
              </div>

              <Form.Group
                className="mb-3 Switch-custom"
                controlId="formExclude"
              >
                {Edit && (
                  <div id="SwitchState">
                    <Row className="mb-3">
                      <Col>
                        <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                          Switch State
                        </Form.Label>
                      </Col>
                      <Col lg="3" className="text-end">
                        <Form.Check
                          type="switch"
                          id="state-switch"
                          onChange={(e) => {}}
                        />
                      </Col>
                    </Row>
                  </div>
                )}

                <div id="exclude" style={{ display: "none" }}>
                  <Row className="mb-3">
                    <Col>
                      <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                        Exclude weekends
                      </Form.Label>
                    </Col>
                    <Col lg="3" className="text-end">
                      <Form.Check
                        type="switch"
                        id="weekends1"
                        onChange={(e) => {
                          weekends1();
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                <div id="excludeone">
                  <Row className="mb-3">
                    <Col>
                      <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                        Exclude weekends
                      </Form.Label>
                    </Col>
                    <Col lg="3" className="text-end">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        onChange={(e) => {
                          weekends();
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                <Modal show={showHolidays} onHide={handleCloseHolidays}>
                  <Modal.Header closeButton>
                    <Modal.Title>UAE Holidays 2022</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table responsive="md">
                      <thead>
                        <tr>
                          <th className="fw-airlight">Date</th>
                          <th className="fw-airlight">Lands on</th>
                          <th className="fw-airlight">Public Holidays</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="fw-airlight text-muted">Jan 1</td>
                          <td className="fw-airlight text-muted">Saturday</td>
                          <td className="fw-airlight text-muted">
                            New Year’s Day
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-airlight text-muted">May 2-5*</td>
                          <td className="fw-airlight text-muted">Mon-Thurs</td>
                          <td className="fw-airlight text-muted">
                            Eid al-Fitr
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-airlight text-muted">July 8</td>
                          <td className="fw-airlight text-muted">Friday</td>
                          <td className="fw-airlight text-muted">Arafat Day</td>
                        </tr>
                        <tr>
                          <td className="fw-airlight text-muted">July 9-11</td>
                          <td className="fw-airlight text-muted">Sat-Mon</td>
                          <td className="fw-airlight text-muted">
                            Eid al-Adha
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-airlight text-muted">July 30</td>
                          <td className="fw-airlight text-muted">Saturday</td>
                          <td className="fw-airlight text-muted">
                            Islamic New Year
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-airlight text-muted">Oct 8</td>
                          <td className="fw-airlight text-muted">Saturday</td>
                          <td className="fw-airlight text-muted">
                            Prophet Muhammad’s Birthday
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-airlight text-muted">Dec 1</td>
                          <td className="fw-airlight text-muted">Thursday</td>
                          <td className="fw-airlight text-muted">
                            Commemoration Day
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-airlight text-muted">Dec 2</td>
                          <td className="fw-airlight text-muted">Friday</td>
                          <td className="fw-airlight text-muted">
                            National Day
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-airlight text-muted">Dec 3</td>
                          <td className="fw-airlight text-muted">Saturday</td>
                          <td className="fw-airlight text-muted">
                            National Day Holiday
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Modal.Body>
                </Modal>
                <Row className="mb-3">
                  <Col>
                    <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                      Exclude public holidays{" "}
                      <span
                        role={"button"}
                        onClick={() => setshowHolidays(true)}
                      >
                        <img
                          src="../img/Icon-Public.svg"
                          alt="icon"
                          className="ms-1"
                        />
                      </span>
                    </Form.Label>
                  </Col>
                  <Col lg="3" className="text-end">
                    <Form.Check type="switch" id="Excludeholidays" />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg="6">
                    <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                      Customize timing{" "}
                    </Form.Label>
                  </Col>
                  <Col className="text-end">
                    <span className="warning-color fs-10 pe-2">
                      <img
                        src="../img/diamond2.svg"
                        alt="icon"
                        className="me-2"
                      />
                      Gold Feature
                    </span>
                    <span class="form-switch">
                      <input
                        type="checkbox"
                        id="timing"
                        class="form-check-input"
                        onChange={(e) => {
                          Timing();
                        }}
                      />
                    </span>
                  </Col>
                </Row>
                <div id="days" style={{ display: "none" }}>
                  <Row
                    className="mb-3 text-start "
                    id="rowmonday"
                    style={{ display: "none" }}
                  >
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Monday From
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange}
                        value={time}
                        step={30}
                        id="TimeOpenMonday"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        To
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange01}
                        value={time01}
                        step={30}
                        id="TimeCloseMonday"
                      />
                    </Form.Group>
                  </Row>

                  <Row
                    className="mb-3 text-start"
                    id="rowtuesday"
                    style={{ display: "none" }}
                  >
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Tuesday From
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange02}
                        value={time02}
                        step={30}
                        id="TimeOpenTuesday"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        To
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange03}
                        value={time03}
                        step={30}
                        id="TimeCloseTuesday"
                      />
                    </Form.Group>
                  </Row>

                  <Row
                    className="mb-3 text-start"
                    id="rowwednesday"
                    style={{ display: "none" }}
                  >
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Wednesday From
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange04}
                        value={time04}
                        step={30}
                        id="TimeOpenWednesday"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        To
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange05}
                        value={time05}
                        step={30}
                        id="TimeCloseWednesday"
                      />
                    </Form.Group>
                  </Row>

                  <Row
                    className="mb-3 text-start"
                    id="rowthursday"
                    style={{ display: "none" }}
                  >
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Thursday From
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange06}
                        value={time06}
                        step={30}
                        id="TimeOpenThursday"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        To
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange07}
                        value={time07}
                        step={30}
                        id="TimeCloseThursday"
                      />
                    </Form.Group>
                  </Row>

                  <Row
                    className="mb-3 text-start"
                    id="rowfriday"
                    style={{ display: "none" }}
                  >
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Friday From
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange08}
                        value={time08}
                        step={30}
                        id="TimeOpenFriday"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        To
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange09}
                        value={time09}
                        step={30}
                        id="TimeCloseFriday"
                      />
                    </Form.Group>
                  </Row>

                  <Row
                    className="mb-3 text-start"
                    id="rowsaturday"
                    style={{ display: "none" }}
                  >
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Saturday From
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange10}
                        value={time10}
                        step={30}
                        id="TimeOpenSaturday"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        To
                      </Form.Label>
                      <TimePicker
                        onChange={handleTimeChange11}
                        value={time11}
                        step={30}
                        id="TimeCloseSaturday"
                      />
                    </Form.Group>
                  </Row>

                  <Row
                    className="mb-3 text-start"
                    id="rowsunday"
                    style={{ display: "none" }}
                  >
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Sunday From
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange12}
                        value={time12}
                        step={30}
                        id="TimeOpenSunday"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        To
                      </Form.Label>

                      <TimePicker
                        onChange={handleTimeChange13}
                        value={time13}
                        step={30}
                        id="TimeCloseSunday"
                      />
                    </Form.Group>
                  </Row>
                </div>
                <Link
                  to=""
                  className="text-muted text-decoration-color fs-12 w-100"
                >
                  Campaign modifications take 24 hours to take effect.
                </Link>
              </Form.Group>
              <Form.Group
                className="mb-3 Switch-custom"
                controlId="formFinePrint"
              >
                <Form.Label className="bogo-par fs-13 text-start fw-airlightf">
                  Fine Print{" "}
                  <span>
                    <img
                      src="../img/Icon-Public.svg"
                      alt="icon"
                      className="ms-1"
                    />
                  </span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  defaultValue={Values?.TermsAndConditions}
                  placeholder="Add any specific exclusions or fine print that applies to this Buy One Get One campaign"
                />
              </Form.Group>
              <Button
                variant="primary"
                className="w-100"
                type="submit"
                onClick={handleClickEvent}
              >
                {Edit ? "Save" : "Submit and Review"}
              </Button>
              {Edit && (
                <>
                  <br />
                  <br />
                  <br />
                </>
              )}
            </div>
          </div>
        </section>
      </Container>
    </main>
  );
}

async function GetLocationsList(setBusinnesses) {
  if (!getToken()) return;
  return await fetch(`${window.$apiurl}/api/Merchant/GetBusinnesses`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${getToken()}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[1].length > 0) setBusinnesses(res[1]);
    })
    .catch((error) => {
      console.error(error);
    });
}

async function UpdateCampaign(body, id, ReloadCampaigns) {
  var branches = [];
  (body?.Branchs ?? []).forEach((element) => {
    branches.push({ Branch: element });
  });

  let Type = body.typeoffer;
  if (Type === "Buy One Get One") Type = 0;
  else if (Type === "Buy X Get Y") Type = 1;
  else if (Type === "Buy Bundle Get Unit") Type = 2;

  var raw = {
    Branches: branches,
    state: body.state,
    TermsAndConditions: body.Description,
    Timings: [],
    Type: {
      Type: Type,
      ToBuy: body.buy,
      Bonus: body.get,
    },
    ExcludeWeekends: body.excludewekkends,
    ExcludeHolidays: body.Excludeholidays,
    CustomizeTiming: body.CustomizeTiming,
    Savings: body.money,
  };
  var array = [
    {
      DayName: "Monday",
      OpenTime: body.timeOpenMonday,
      CloseTime: body.timeCloseMonday,
    },
    {
      DayName: "Tuesday",
      OpenTime: body.timeOpenTuesday1,
      CloseTime: body.timeCloseTuesday2,
    },
    {
      DayName: "Wednesday",
      OpenTime: body.timeOpenWednesday3,
      CloseTime: body.timeCloseWednesday4,
    },
    {
      DayName: "Thursday",
      OpenTime: body.timeOpenThursday5,
      CloseTime: body.timeCloseThursday6,
    },
    {
      DayName: "Friday",
      OpenTime: body.timeOpenFriday7,
      CloseTime: body.timeCloseFriday8,
    },
    {
      DayName: "Saturday",
      OpenTime: body.TimeOpenSaturday,
      CloseTime: body.TimeCloseSaturday,
    },
    {
      DayName: "Sunday",
      OpenTime: body.TimeOpenSunday,
      CloseTime: body.TimeCloseSunday,
    },
  ];

  array.forEach((element) => {
    if (
      (element.DayName === "Monday" &&
        (body?.valuecheck6 || body?.valuecheck06)) ||
      (element.DayName === "Tuesday" &&
        (body?.valuecheck5 || body?.valuecheck05)) ||
      (element.DayName === "Wednesday" &&
        (body?.valuecheck4 || body?.valuecheck04)) ||
      (element.DayName === "Thursday" &&
        (body?.valuecheck3 || body?.valuecheck03)) ||
      (element.DayName === "Friday" &&
        (body?.valuecheck2 || body?.valuecheck02)) ||
      (element.DayName === "Saturday" &&
        (body?.valuecheck || body?.valuecheck01)) ||
      (element.DayName === "Sunday" &&
        (body?.valuecheck7 || body?.valuecheck07))
    )
      raw.Timings.push(element);
  });

  return await fetch(`${window.$apiurl}/api/Merchant/UpdateCampaign/${id}`, {
    method: "PATCH",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${getToken()}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(raw),
  })
    .then(async (response) => {
      const statusCode = await response.status;
      if (statusCode === 200) {
        NotificationSuccess({ message: "Success" });
        if (ReloadCampaigns) ReloadCampaigns();
      }
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .catch((error) => {
      console.error(error);
    });
}
